export const tableColumns = [
	{
		model: 'AttractionId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'AttractionName',
		i18n: 'htitle',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AttractionUrlName',
		i18n: 'url',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupId',
		i18n: 'group',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CityName',
		i18n: 'locality',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
		filter: 'country',
	},
	{
		model: 'AttractionIsVerified',
		i18n: 'checked',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'AttractionTextFilled',
		i18n: 'translations',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
];
