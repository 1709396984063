<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:ref="$options.name"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="attractions2705"
		tooltipMsg="manualforattrac"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'SpageUrl', ascending: 1 }"
		modelId="AttractionId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="AttractionTable"
	>
		<template #AttractionName="list">
			<a :href="list.row.AttractionDomainGroupUrl + list.row.AttractionUrlName" target="_blank">{{ list.row.AttractionName }}</a>
		</template>

		<template #AgroupId="list">{{ formatGroup(list.row.AgroupId) }}</template>

		<template #AttractionTextFilled="list">{{ formatFilled(list.row.AttractionTextFilled) }}</template>

		<template #AttractionIsVerified="list">
			<boolean-display :boolValue="Boolean(list.row.AttractionIsVerified)"></boolean-display>
		</template>

		<template #CountryId="list">
			<country-flag :countryId="Number(list.row.CountryId)"></country-flag>
		</template>

		<template #customActions="list">
			<megau-button
				v-if="showDeleteButton()"
				classprop="btn btn-customdanger ml-1"
				icon="trash-alt"
				:tooltip="$t('delete')"
				@handleClick="openModal(list.row.AttractionId)"
			></megau-button>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import serviceDictionary from '@/services/service/dictionary.service';
import serviceCommon from '@/services/service/common.service';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';
import BooleanDisplay from '@/components/common/boolean-display';
import CountryFlag from '@/components/common/country-flag';

import { tableColumns } from './attraction.table-data';

export default {
	name: 'AttractionTable',

	components: {
		TablePage,
		BooleanDisplay,
		CountryFlag,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'AttractionListItem',
			attractionGroups: null,
		};
	},

	async created() {
		this.attractionGroups = await serviceDictionary.getAttractionGroupEnum();

		this.columns = await this.prepareColumns();
	},

	methods: {
		redirectToDetail(AttractionId = 0) {
			this.$router.push({
				name: links.attractionDetailName,
				params: { AttractionId },
			});
		},

		async prepareColumns() {
			let tblCols = prepareAgroupId(tableColumns, this.attractionGroups);
			tblCols = prepareAttractionTextFilled(tblCols, this.$store);

			return tblCols;

			function prepareAgroupId(cols, attractionGroups) {
				const AgroupIdIndex = cols.findIndex((c) => c.model === 'AgroupId');

				cols[AgroupIdIndex].filterOptions.dropdownOptions = attractionGroups.attractionGroupSelectorItems;

				return cols;
			}

			function prepareAttractionTextFilled(cols, $store) {
				const AttractionTextFilledIndex = cols.findIndex((c) => c.model === 'AttractionTextFilled');

				cols[AttractionTextFilledIndex].filterOptions.dropdownOptions = $store.getters.countryLanguageCodeForDropdown;

				return cols;
			}
		},

		formatGroup(groupId) {
			return this.attractionGroups.attractionGroupDict ? this.attractionGroups.attractionGroupDict[groupId - 1].Text : '';
		},

		formatFilled(text) {
			return text ? text.slice(0, -2) : text;
		},

		showDeleteButton() {
			return auth.IsAllowedForUser(enums.role.Admin);
		},

		openModal(itemId) {
			serviceCommon.openDeleteModal(itemId, this.$modal, this.deleteThisItem);
		},

		deleteThisItem(id) {
			serviceCommon.deleteItem(id, this.tableGetData, this.controllerName);
		},
	},
};
</script>
<style lang="scss">
.AttractionTable {
	.VueTables__filters-row > th:nth-child(2) {
		min-width: 55px;
		width: 55px;
	}
}
</style>