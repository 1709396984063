var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: _vm.$options.name,
        staticClass: "AttractionTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          headerTitle: "attractions2705",
          tooltipMsg: "manualforattrac",
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "SpageUrl", ascending: 1 },
          modelId: "AttractionId",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "AttractionName",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          list.row.AttractionDomainGroupUrl +
                          list.row.AttractionUrlName,
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(list.row.AttractionName))]
                  ),
                ]
              },
            },
            {
              key: "AgroupId",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm.formatGroup(list.row.AgroupId)))]
              },
            },
            {
              key: "AttractionTextFilled",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(_vm.formatFilled(list.row.AttractionTextFilled))
                  ),
                ]
              },
            },
            {
              key: "AttractionIsVerified",
              fn: function (list) {
                return [
                  _c("boolean-display", {
                    attrs: {
                      boolValue: Boolean(list.row.AttractionIsVerified),
                    },
                  }),
                ]
              },
            },
            {
              key: "CountryId",
              fn: function (list) {
                return [
                  _c("country-flag", {
                    attrs: { countryId: Number(list.row.CountryId) },
                  }),
                ]
              },
            },
            {
              key: "customActions",
              fn: function (list) {
                return [
                  _vm.showDeleteButton()
                    ? _c("megau-button", {
                        attrs: {
                          classprop: "btn btn-customdanger ml-1",
                          icon: "trash-alt",
                          tooltip: _vm.$t("delete"),
                        },
                        on: {
                          handleClick: function ($event) {
                            return _vm.openModal(list.row.AttractionId)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          false,
          853730940
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }